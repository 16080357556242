import React, { FC, useState, useMemo, useRef } from 'react';
import {
  Layout,
  HomeBanner,
  LatestArticles,
  PopularArticles,
  Categories,
  TagsFilter,
  Pagination,
} from 'components';
import { TagQuery } from 'queries/tags';
import { LatestArticlesQuery } from 'queries/latestArticles';
import info from '../data.json';

const PageSize = 10;

const IndexPage: FC = () => {
  const tags = TagQuery();
  const taglist = tags?.allWpTag?.edges;

  const allArticles = LatestArticlesQuery();
  const ArticlesList = allArticles?.allWpPost?.edges;

  const [currentPage, setCurrentPage] = useState(1);

  const ArticlesTagsFilter = taglist;
  const ArticlesListData: any = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return ArticlesList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  const titleref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleonClick = (page: any) => {
    setCurrentPage(page);
  };

  const newpageTitle = info.filter((item: any) => {
    return item.pagetitle === 'Home Page';
  });
  return (
    <Layout
      Allarticles={ArticlesListData}
      pageTitle={newpageTitle[0].title}
      description={newpageTitle[0].description}
      image={newpageTitle[0].image}
    >
      <HomeBanner />
      <h2 className="section-heading">Latest Articles</h2>
      <div className="article_tag_main">
        <div className="latest-articles">
          <LatestArticles Allarticles={ArticlesListData} />
          <Pagination
            currentPage={currentPage}
            totalCount={ArticlesList.length}
            pageSize={PageSize}
            onPageChange={handleonClick}
          />
        </div>
        <div className="article_tag_wrapper">
          <Categories />
          <TagsFilter Filters={ArticlesTagsFilter} titleref={titleref} />
          <PopularArticles />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
