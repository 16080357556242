import { useStaticQuery, graphql } from 'gatsby';

const TagQuery = () => {
  return useStaticQuery(graphql`
    query TagQuery {
      allWpTag {
        edges {
          node {
            name
            slug
            uri
            count
          }
        }
      }
    }
  `);
};

export { TagQuery };
