import { useStaticQuery, graphql } from 'gatsby';

const LatestArticlesQuery = () => {
  return useStaticQuery(graphql`
    query LatestArticlesQuery {
      allWpPost(limit: 6) {
        edges {
          node {
            categories {
              nodes {
                name
                uri
              }
            }
            tags {
              nodes {
                name
              }
            }
            date
            commentCount
            title
            content
            uri
            author {
              node {
                name
              }
            }
            featuredImage {
              node {
                localFile {
                  childrenImageSharp {
                    fluid(maxWidth: 340, maxHeight: 180) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
};

export { LatestArticlesQuery };
